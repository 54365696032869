<template>
  <div class="receivable-list">
    <section class="content">
      <section style="margin-bottom: 1%">
        <a-button type="primary" @click="createHandle">
          <i class="icon iconfont icon-new" style="margin-right: 5px"></i>
          {{ $t('新增应收单') }}
        </a-button>
      </section>
      <a-row :gutter="16">
        <a-col :span="6">
          <a-input
            allow-clear
            v-model.trim="formData.orderSn"
            :placeholder="$t('请输入应收单号')"
          />
        </a-col>
        <a-col :span="6">
          <date-range-picker
            :needhms="false"
            @get_date="chooseAccountingDate"
            :placeholder="$t(`记账日期`)"
            :start-time="formData.accountingDateStart"
            :end-time="formData.accountingDateEnd"
          ></date-range-picker>
        </a-col>
        <a-col :span="6">
          <CommonSelect
            :isBu="true"
            :list="$store.state.settlementBuList"
            :code.sync="formData.businessUnitCode"
            :name.sync="formData.businessUnitName"
            :placeholder="$t(`收款组织`)"
          />
        </a-col>
        <a-col :span="6">
          <CommonQuerySelect
            api="getCommonList"
            :placeholder="$t('客户')"
            :code.sync="formData.customerCode"
            :params="{
              businessUnitCode: formData.businessUnitCode,
              tableName: 'customer',
            }"
          />
        </a-col>
      </a-row>
      <a-row :gutter="16" style="margin-top: 15px">
        <a-col :span="6">
          <CommonSelect
            :list="receivable_order_type"
            :code.sync="formData.orderType"
            :placeholder="$t('单据类型')"
          ></CommonSelect>
        </a-col>
        <a-col :span="6">
          <a-button @click="searchHandle(1)" class="btnBgc">
            <i class="iconfont" style="margin-right: 5px">&#xe647;</i>
            {{ $t(`查询`) }}
          </a-button>
          <a-button @click="reset">
            <a-icon type="redo" />
            {{ $t(`重置`) }}
          </a-button>
        </a-col>
      </a-row>

      <CommonTable
        ref="CommonTableRef"
        :statusList="receivable_order_status"
        :selectedRowKeys.sync="selectedRowKeys"
        :selectedRows.sync="selectedRows"
        :showSelect="true"
        size="small"
        :scroll="{ x: 1500, y: scrollHeight }"
        :columns.sync="tableColumns"
        :data-source="tableData"
        rowKey="id"
        :total="total"
        :loading="tableLoading"
        @select="onSelectChange"
        @search="queryOrderList"
      >
        <template v-slot:header>
          <div class="flex-ct">
            <OperateBtn
              api="operateReceivableOrder"
              :text="$t('审核')"
              :params="{
                action: 'check',
                idList: selectedRowKeys,
              }"
              :disabled="selectedRowKeys.length === 0"
              @success="searchHandle"
            />
            <PushBtn
              class="ml10"
              pageType="list"
              :docType="docType"
              :selectedRows="selectedRows"
              :disabled="selectedRows.length === 0"
            />
            <VoucherBtn
              class="ml10"
              :docType="docType"
              :selectedRows="selectedRows"
              :disabled="selectedRows.length === 0"
              @close="searchHandle"
            />
          </div>
        </template>
        <template v-slot:orderSn="{ record }">
          <a @click="viewDetail(record)">{{ record.orderSn }}</a>
        </template>
        <template v-slot:amountWithoutTax="{ record }">
          <PriceInput
            :showTxt="true"
            :currency="record.functionalCurrency"
            :value="record.amountWithoutTax"
          />
        </template>
        <template v-slot:taxAmount="{ record }">
          <PriceInput
            :showTxt="true"
            :currency="record.functionalCurrency"
            :value="record.taxAmount"
          />
        </template>
        <template v-slot:receivableAmount="{ record }">
          <PriceInput
            :showTxt="true"
            :currency="record.functionalCurrency"
            :value="record.receivableAmount"
          />
        </template>
        <template v-slot:invoicedAmount="{ record }">
          <PriceInput
            :showTxt="true"
            :currency="record.functionalCurrency"
            :value="record.invoicedAmount"
          />
        </template>
        <!-- 业务单号 -->
        <template v-slot:otherSns="{ record }">
          <div v-if="record.salesOrderSn">
            {{ $t('销售单号') }}:
            <span class="text-link">{{ record.salesOrderSn }}</span>
          </div>
          <div v-if="record.deliverOrderSn">
            {{ $t('发货单号') }}:
            <span class="text-link">{{ record.deliverOrderSn }}</span>
          </div>
        </template>
        <template v-slot:sourceOrderCode="{ record }">
          <a>{{ record.sourceOrderCode }}</a>
        </template>
      </CommonTable>
    </section>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import http from '@/service/axios'
import api from '@/service/api'
import dateRangePicker from '@component/dateRangePicker'
import PushBtn from '@component/pushBtn'
import { convertKeysToCamelCase, spaceToComma } from '@/common'
import { subtract } from '@/common/number'

export default {
  name: 'receivableList',
  activated() {
    this.searchHandle()
  },
  components: {
    dateRangePicker,
    PushBtn,
  },
  data() {
    return {
      docType: 'ReceivableOrder',
      formData: {
        orderSn: undefined,
        orderTimeStart: undefined,
        orderTimeEnd: undefined,
        status: '',
        supplierName: undefined,
      },
      tableLoading: false,
      total: 0,
      selectedRowKeys: [],
      selectedRows: [],
      tableData: [],
      tableColumns: [
        {
          dataIndex: 'orderSn',
          title: this.$t('应收单号'),
          key: 'orderSn',
          width: 200,
          scopedSlots: { customRender: 'orderSn' },
        },
        {
          title: this.$t('单据类型'),
          dataIndex: 'orderType',
          customRender: (text) => this.receivableOrderTypeMapping[text],
          width: 80,
        },
        {
          title: this.$t('业务日期'),
          dataIndex: 'bizDate',
          width: 200,
        },
        {
          title: this.$t('单据状态'),
          dataIndex: 'status',
          customRender: (text) => this.receivableOrderStatusMapping[text],
          width: 80,
        },
        {
          title: this.$t('客户'),
          dataIndex: 'customerName',
          width: 150,
        },
        {
          title: this.$t('金额') + this.$t('未含税'),
          width: 120,
          dataIndex: 'amountWithoutTax',
          scopedSlots: { customRender: 'amountWithoutTax' },
        },
        {
          title: this.$t('税额'),
          width: 120,
          dataIndex: 'taxAmount',
          scopedSlots: { customRender: 'taxAmount' },
        },
        {
          title: this.$t('应收金额'),
          dataIndex: 'receivableAmount',
          width: 120,
          scopedSlots: { customRender: 'receivableAmount' },
        },
        {
          title: this.$t('开票金额'),
          dataIndex: 'invoicedAmount',
          width: 120,
          scopedSlots: { customRender: 'invoicedAmount' },
        },
        {
          title: this.$t('结算组织'),
          dataIndex: 'businessUnitName',
          width: 150,
        },
        {
          title: this.$t('是否生成凭证'),
          dataIndex: 'voucherGenerated',
          width: 100,
          customRender: (text) => (text ? this.$t('是') : this.$t('否')),
        },
        {
          title: this.$t('凭证号'),
          dataIndex: 'voucherNo',
          width: 100,
        },
        {
          title: this.$t('业务单号'),
          dataIndex: 'otherSns',
          width: 320,
          scopedSlots: { customRender: 'otherSns' },
        },
        {
          title: this.$t('源单类型'),
          dataIndex: 'sourceOrderType',
          width: 150,
          customRender: (text) => this.$store.getters.sourceOrderTypeMapping[text],
        },
        {
          title: this.$t('源单编码'),
          dataIndex: 'sourceOrderCode',
          width: 180,
          scopedSlots: { customRender: 'sourceOrderCode' },
        },
      ],
    }
  },
  computed: {
    ...mapState(['receivable_order_status', 'receivable_order_type']),
    ...mapGetters(['receivableOrderStatusMapping', 'receivableOrderTypeMapping']),
    scrollHeight() {
      return window.innerHeight - 470
    },
  },
  methods: {
    ...mapMutations(['addPane']),
    subtract,
    reset() {
      Object.assign(this.$data, this.$options.data())
      this.searchHandle(1)
    },
    searchHandle(pageNo) {
      this.selectedRowKeys = []
      this.selectedRows = []
      this.$refs.CommonTableRef.queryList(pageNo)
    },
    supplierIndexChange(val) {
      this.formData.supplierName = val.supplierName
    },
    createHandle() {
      this.$router.push({ name: 'receivableDetail' })
    },
    viewDetail(record) {
      this.addPane({
        view: 'receivableDetail/' + record.orderSn,
        name: 'detailSn',
        query: {
          id: record.id,
          sn: record.orderSn,
        },
        route: `/receivableManage/receivableDetail?id=${record.id}&sn=${record.orderSn}`,
      })
    },
    chooseAccountingDate(date) {
      this.formData.accountingDateStart = date.start_time
      this.formData.accountingDateEnd = date.end_time
    },
    async queryOrderList(params) {
      let data = {
        ...this.formData,
        ...params,
      }
      let orderSn = spaceToComma(this.formData.orderSn)
      if (orderSn.includes(',')) {
        data.orderSnList = orderSn.split(',')
      } else if (orderSn) {
        data.orderSn = orderSn
      }
      this.tableLoading = true
      let res = await http({
        type: 'post',
        url: api.getReceivableList,
        data,
      })
      this.tableData = convertKeysToCamelCase(res.result.list || [])
      this.total = res.result.total
      this.tableLoading = false
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@css/mixin.scss';
.btnBgc {
  background-color: #0088ff;
  color: #ffffff;
}
</style>
